body {
  height: 100%;
  border: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  font-size: 20px;
}

.text-container {
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100vh;
  width: 100vw;
}

div.is-loading {
  margin-bottom: 12px;
}

textarea {
  font-size: 16px;
  height: 100%;
  width: 100%;
  overflow-y: visible;
  overflow-x: visible;
  box-shadow: 0 0 0 0;
  border: none;
  outline: none;
  resize: none;
}
