.home-container {
  align-items: center;
  justify-content: space-between;
}

#title {
  margin: 50px;
  text-align: center;
  font-size: 50px;
}

.links-box {
  margin: 20px 0;
  display: flex;
}

.links-row {
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 50px;
}

#link-input {
  height: 24px;
  width: 320px;
}

#apresentacao {
  line-height: 25px;
  margin-top: 10px;
}

#botao {
  height: 28px;
  width: 40px;
}

/* Kudos to GH Copilot because he's the one that did the css below */
@media (max-width: 768px) {
  #title {
    font-size: 40px;
  }

  #apresentacao {
    font-size: 15px;
  }

  #link-input {
    height: 32px;
    width: 70%;
  }

  #botao {
    height: 36px;
    width: 12%;
  }

  .links-box {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .link-input-row {
    margin-top: 8px;
  }
}

@media (min-width: 768px) {
  .links-box {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}